<template>
  <div class="ladies project-component">
    <div class="fullsection">
      <p>
        Collaborating in a team of 6, we had 3 days to come up with an innovative solution using the Cloud to fight
        climate change.

      </p>
      <br />
      <p>
        We spend 2 days getting coached at the Parisian google headquarters by Thales and Google consultants. We came up
        with Greenmile, a mobile app to track your daily carbon footprint using data already available to us using
        google’s collected data, and complementary manually inputs.

      </p>
      <br />
    </div>
    <div class="half-section half-section-media ">
      <img src="@/assets/projects/google/album/2.png" alt="" />
    </div>
    <div class="half-section half-section-media">
      <img src="@/assets/projects/google/album/3.png" alt="" />
    </div>
    <p>
      I then got to pitch our project to more than 300 people, and partake in the annual Google night !
    </p>


    <div class="full-section half-section-media">
      <img src="@/assets/projects/google/album/1.png" alt="" />
    </div>



    <div class="lesson-box">
      <h2>What did I learn ?</h2>
      <br />
      <p>
        I learnt how to quickly come up with an innovative idea as a group, but also about the different innovative
        applications for the Cloud. I would 100% recommend hackathons (And the Google night of course)!
      </p>
    </div>
  </div>
</template>
  
<script>
export default {
  name: "Google",
};
</script>
<style scoped>

</style>